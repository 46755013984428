<template>
<div class="domain-page">
  <v-alert type="success" v-if="copied" class="copied">
    Copied!
  </v-alert>
  <div v-if="landing.profi">
    <label style="font-weight:bold;font-size:20px;display:block;margin-bottom:15px;" class="label">Domain settings</label>
    <div>
      <div class="form">
        <div class="field">
          <label class="label" style="display:block;margin-bottom: 15px">Choose your custom domain name</label>
          <v-text-field
              class="input input_dense"
              outlined
              solo
              flat
              dense
              placeholder="Write your domain name"
              v-model="domain"
              @change="changeDomain"
          ></v-text-field>
        </div>
      </div>

    </div>
    <div style="margin-bottom: 15px;">
      <label class="label" style="margin-bottom: 15px;">Instruction for specifying A records</label>
      <p style="margin-bottom: 30px;">
        The A (address) record is one of the key resource records on the Internet. It is needed to connect the domain with the server IP address. Until an A-record is registered, your domain will not work.
        When you enter the name of the site into the address bar of the browser, it is by the A-record that the DNS determines from which domain you need to open your hotel site.
      </p>
      <label class="label">Change the value of the value field in your domain registrar to the value indicated below:</label>
      <br>
      <br>
      <v-simple-table class="elevation-1">
        <thead>
        <tr>
          <th>Name</th>
          <th>Record type</th>
          <th>Value</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td class="d-block d-sm-table-cell" v-if="domain">{{ domain.slice(0,19) }}</td>
          <td class="d-block d-sm-table-cell" v-else>Your Domain Name</td>
          <td class="d-block d-sm-table-cell">A</td>
          <td class="d-block d-sm-table-cell">159.89.22.187 <v-icon color="primary" small @click="copyIp()">mdi-content-copy</v-icon> </td>
        </tr>
        </tbody>
      </v-simple-table>
    </div>
    <div>
      <label class="label" style="display:block;margin-bottom: 15px">Write your custom id</label>
      <v-text-field
          class="input input_dense"
          outlined
          solo
          flat
          dense
          :rules="customIDRules"
          placeholder="Write your custom id"
          v-model="$store.state.objects.item.custom_id"
          @change="changeDomain"
      ></v-text-field>
    </div>
  </div>
  <div style="display: flex;padding:20px 0;" v-if="!landing.profi">
    <v-icon color="#cccccc" size="60px" style="margin-right: 20px;">mdi-lock</v-icon>
    <h1 class="constructor__heading" style="font-weight: normal;font-size: 16px;">This feature is available on the Pro plans.
      <br>To activate the tariff <a @click="billing">click here</a></h1>
  </div>
  <PopupTariff ref="PopupTariff"/>
</div>
</template>

<script>
import {mapMutations, mapState} from 'vuex'
export default {
  name: "DomainPage",
  data(){
    return{
      domain:"",
      copied:false,
      customIDRules: [
        v => /^[0-9a-z]/.test(v) || 'You should use only numbers and small letters.',
        v => /(?=.*[a-z])/.test(v) || 'One small letter is required.',
        v => v.length <= 30 || 'Max count of id 30 symbols.'
      ],
    }
  },
  components:{
    PopupTariff: () => import("../../Popup/PopupTariff"),
  },
  computed:{
    ...mapState(['landing']),
  },
  methods:{
    ...mapMutations('landing',['setLandingDomain']),
    changeDomain(){
      this.setLandingDomain(this.domain);
    },
    copyIp(){
      this.copied = true;
      navigator.clipboard.writeText('159.89.22.187');
      setTimeout(() => {
        this.copied = false;
      },2000)
    },
    billing(){
      let obj = this.$store.state.objects.list.find(x => +x.id === +this.$route.params.id);
      this.$refs.PopupTariff.open(obj);
    },
  },
  mounted(){
    this.domain = this.landing.domain;
  }
}
</script>

<style scoped lang="scss">
.v-application--is-ltr .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-application--is-ltr .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-application--is-ltr .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th, table td{
  text-align: center;
}
.copied{
  position: fixed;
  left:50%;
  margin-left:-65px;
  top:5px;
  z-index: 10;
}
</style>
